import {
    BookmarkAltIcon,
    BookOpenIcon,
    RssIcon,
    ViewListIcon
}                                   from '@heroicons/react/outline'
import {
    CheckIcon,
    ChevronRightIcon
}                                   from '@heroicons/react/solid'
import { Page }                     from '@open-law/cms-schema-types'
import { Slug }                     from '@open-law/open-law-shared'
import {
    graphql,
    PageProps
}                                   from 'gatsby'
import React, { FunctionComponent } from 'react'

import Layout from '../components/layout'
import SEO    from '../components/seo'

export const query = graphql`
    query PagesForSitemap {
        routesGroupedByPageCategory: allSanityRoute(sort: {fields: page___pageCategory___title}) {
            group(field: page___pageCategory___title) {
                fieldValue
                edges {
                    node {
                        slug {
                            current
                        }
                        page {
                            title
                            pageCategory {
                                title
                            }
                        }
                        id
                    }
                }
            }
        }
    }
`

type PagesGroupedByCategory = { group: { fieldValue: string, edges: { node: { id: string, page: Page, slug: Slug } }[] }[] }
type SitemapPageData = {
    routesGroupedByPageCategory: PagesGroupedByCategory
}

interface SitemapProps extends PageProps<SitemapPageData, null> {
}


const Sitemap: FunctionComponent<SitemapProps> = (props) => {

    // console.log(props)

    const pageGroups = props.data.routesGroupedByPageCategory as PagesGroupedByCategory

    // console.log(pageGroups)
    const links = [
        {title: 'Projects', description: `Learn about Open Law's initiatives`, icon: BookOpenIcon},
        {title: 'Access', description: 'See options for accessing Open Law projects and services', icon: ViewListIcon},
        {title: 'Webinars', description: 'Improve your legal research speed and know-how', icon: BookmarkAltIcon},
        {title: 'Quickstart', description: 'Short videos about legal research and Open Law services', icon: CheckIcon},
        {title: 'Events', description: 'Register for a training session', icon: RssIcon}
    ]

    const mapGroupName = (name?: string) => {
        if (name) {
            switch (name) {
                case 'Core Pages': {
                    return 'Core Pages'
                }
                case 'JADE Enterprise': {
                    return 'JADE Enterprise'
                }
                case 'JADE Free - Anonymous': {
                    return 'JADE Free'
                }
                case 'JADE Professional': {
                    return 'JADE Professional'
                }
                case 'JP Subscriber': {
                    return 'JADE Professional Subscribers'
                }
                case 'Training Events': {
                    return 'Education and Training Events'
                }
                default: {
                    return name
                }
            }
        }
        return ''
    }


    return (
        <Layout>
            <SEO title="Sitemap"/>
            <section className={'max-w-7xl mx-auto'}>
                <section>
                    <div className="bg-white">
                        <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="max-w-xl mx-auto py-16 sm:py-24">
                                <div className="text-center">
                                    <p className="text-sm font-semibold text-blue-600 uppercase tracking-wide">
                                        Sitemap
                                    </p>
                                    <h2 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        Find a page
                                    </h2>
                                    <p className="mt-2 text-lg text-gray-500">Every page on Open Law by category.</p>

                                </div>
                            </div>
                        </main>
                    </div>
                </section>
                <div className="max-w-3xl mx-auto p-4 mt-12 mb-12">
                    <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">
                        Popular pages
                    </h2>
                    <ul role="list"
                        className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                        {
                            links.map((link, linkIdx) => (
                                <li key={linkIdx} className="relative py-6 flex items-start space-x-4">
                                    <div className="flex-shrink-0">
                    <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-blue-50">
                      <link.icon className="h-6 w-6 text-blue-700" aria-hidden="true"/>
                    </span>
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <h3 className="text-base font-medium text-gray-900">
                      <span
                          className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                        <a href={`/${link.title.toLowerCase()}`} className="focus:outline-none">
                          <span className="absolute inset-0" aria-hidden="true"/>
                            {link.title}
                        </a>
                      </span>
                                        </h3>
                                        <p className="text-base text-gray-500">{link.description}</p>
                                    </div>
                                    <div className="flex-shrink-0 self-center">
                                        <ChevronRightIcon className="h-5 w-5 text-gray-400"
                                                          aria-hidden="true"/>
                                    </div>
                                </li>
                            ))}
                    </ul>

                </div>
                <div className={'max-w-3xl mx-auto'}>
                    <div className={''}>
                        {
                            pageGroups ? pageGroups.group.sort((a, b) => mapGroupName(a.fieldValue) > mapGroupName(b.fieldValue) ? 1 : -1)
                                .map((group, idx) => (
                                        <div className={'p-4 sm:p-0'}>
                                            <h2 className={'text-2xl my-2 sm:my-8 font-medium'}>
                                                {mapGroupName(group.fieldValue)}
                                            </h2>
                                            <ul className={'flex flex-col mb-2 sm:mb-4'}>
                                                {
                                                    group.edges.sort((a, b) => a.node.page.title > b.node.page.title ? 1 : -1)
                                                        .map(({node}) => (
                                                            <li className={'mb-2'}>
                                                                <a className={'cursor-pointer text-blue-800'}
                                                                   href={`/${node.slug.current}`}>{node.page.title}</a>
                                                            </li>
                                                        ))
                                                }
                                            </ul>
                                            <div className="relative">
                                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div className="w-full border-t border-gray-300"></div>
                                                </div>
                                                <div className="relative flex justify-end">
                                                <span
                                                    className="pl-2 bg-white text-sm text-gray-500">End {mapGroupName(group.fieldValue)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ) : null
                        }
                    </div>

                    <div className="max-w-7xl mx-auto my-8">
                        <a href="/" className="text-base font-medium text-blue-600 hover:text-blue-500">
                            Or go back home
                            <span aria-hidden="true"> &rarr;</span>
                        </a>
                    </div>
                </div>


            </section>

        </Layout>
    )
}

export default Sitemap
